import React, { Component, useState } from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby';
import SEO from "../components/seo"
import { useForm } from 'react-hook-form';
import { Tooltip } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { Info } from '@styled-icons/evaicons-solid/';
import ContactUsSuccess from '../images/ContactUs-Success.png';
import ContactUsError from '../images/ContactUs-Error.png';
import 'react-phone-input-2/lib/style.css';
import styled, { css } from "styled-components"

const customStyles = {
    control: base => ({
        ...base,
        width: 1006.8,
        border: "1px solid rgb(185, 185, 185)",
        borderRadius: "1.5px",
        fontFamily: "Yantramanav", fontSize: "14px", fontWeight: "400"
    }),
    placeholder: base => ({
        ...base,
        fontFamily: "Yantramanav", fontSize: "14px", fontWeight: "400", padding: "0px 6px"
    }),
    input: base => ({
        ...base,
        fontFamily: "Yantramanav", fontSize: "14px", fontWeight: "400", padding: "0px 6px", color: "black"
    }),
    menu: base => ({
        ...base,
        width: 1006.8,
        fontFamily: "Yantramanav", fontSize: "14px", fontWeight: "400"
    })

};

const Input = styled.input`
  &::placeholder { 
    color: #b9b9b9;
    opacity: 1; 
  }
  width: 90%;
  color: #121212;
  height: 25px; 
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius:1.5px;
  margin-right: 30px;
  border:1px solid #e2e2e2;
  font-family: "Yantramanav";
`

const Contact = {
    FormComponent: 0,
    ErrorComponent: 1,
    SuccessComponent: 2
};

function ContactUs({ changeSuccess, changeError }) {
    const { register, handleSubmit, errors } = useForm();
    const [phoneNumber, setPhoneNumber] = useState();
    const [viewPhoneInfo, setViewPhoneInfo] = useState(false);
    const [subject, setSubject] = useState();

    const submit = () => {
        changeSuccess();
    }

    return (
        <Layout>
            <SEO title="Contact Us" />
            <section style={{ "padding": "100px 6.5% 68px", width: "1024px", "margin": "0 auto" }}>
                <h1 style={{ "fontFamily": "Yantramanav", "fontSize": "32px", paddingTop: "16px" }}>Contact Us</h1>
                <h2 style={{ "fontFamily": "Yantramanav", "fontSize": "18px", "fontWeight": "400", "color": "#5e5e5e", marginBottom: "56px" }}>
                    For more information, contact us via email at <a style={{ "color": "#3eb0b4", "textDecoration": "none" }} href="mailto:contact@juristerra.net">contact@juristerra.net</a> or
                     call us at <a style={{ "color": "#3eb0b4", "textDecoration": "none" }} href="tel:+19494247045">+1(949)424-7045</a>
                </h2>
                <section>
                    <form
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            columnGap: "32px",
                            rowGap: "30px",
                            marginTop: "16px"
                        }}
                        onSubmit={handleSubmit(submit)}
                    >
                        <div>
                            <label style={{ fontFamily: "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
                                First name
                            </label>
                            <br />
                            <input
                                style={{ fontFamily: "Yantramanav", "fontSize": "14px", width: "90%", padding: "8px 16px", border: "1px solid #b9b9b9", borderRadius: "1.5px" }}
                                name='firstname'
                                placeholder='First name (required)'
                                ref={register({ required: true })}
                            />
                            {errors.firstname && <span style={{ fontFamily: "Yantramanav", fontSize: "14px", color: "#dc3545" }}>Please enter your first name</span>}
                        </div>
                        <div>
                            <label style={{ "fontFamily": "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
                                Last name
                            </label>
                            <br />
                            <input
                                style={{ fontFamily: "Yantramanav", "fontSize": "14px", width: "90%", padding: "8px 16px", border: "1px solid #b9b9b9", borderRadius: "1.5px" }}
                                name='lastname'
                                placeholder='Last name (required)'
                                ref={register({ required: true })}
                            />
                            {errors.lastname && <span style={{ fontFamily: "Yantramanav", fontSize: "14px", color: "#dc3545" }}>Please enter your last name</span>}
                        </div>
                        <div>
                            <label style={{ "fontFamily": "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
                                Email
                            </label>
                            <br />
                            <input
                                style={{ fontFamily: "Yantramanav", "fontSize": "14px", width: "90%", padding: "8px 16px", border: "1px solid #b9b9b9", borderRadius: "1.5px" }}
                                name='email'
                                type='email'
                                placeholder='Email (required)'
                                ref={register({ required: true })}
                            />
                            {errors.email && <span style={{ fontFamily: "Yantramanav", fontSize: "14px", color: "#dc3545" }}>Please enter your email (e.g. jdoe@gmail.com)</span>}
                        </div>
                        <div>
                            <label style={{ "fontFamily": "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
                                Phone number
                            </label>
                            <div style={{ "position": "relative" }}>
                                <PhoneInput
                                    // style={{ borderRadius: "1.5px", width: "100%" }}
                                    country={'us'}
                                    value={phoneNumber}
                                    placeholder='(123) 123-1345 (optional)'
                                    masks={{ us: '(...) ...-....' }}
                                    onChange={data => setPhoneNumber(data)}
                                    inputStyle={{ width: "482px", height: "36px", border: "1px solid #b9b9b9", borderRadius: "1.5px", fontFamily: "Yantramanav", fontSize: "14px", fontWeight: "400" }}
                                />
                                <Info size='13px' color='#b9b9b9' id='tooltip-info' style={{ position: "absolute", right: "20px", top: "12px" }} onMouseEnter={() => setViewPhoneInfo(true)} onMouseLeave={() => setViewPhoneInfo(false)} />
                                <Tooltip
                                    style={{ position: "absolute", right: "-15px", top: "15px", width: "334px", padding: "20px 26px", fontFamily: "Yantramanav", fontSize: "14px", color: "white", backgroundColor: "#353535", borderRadius: "3px" }}
                                    placement='bottom-end'
                                    isOpen={viewPhoneInfo}
                                    target='tooltip-info'
                                >
                                    When you provide your phone number, we can
                                    contact you to discuss your project if requested
                                    a project manager. We will never share any of your information
                                    with 3rd party companies.
                            </Tooltip>
                            </div>
                        </div>
                        <div style={{ "gridColumnStart": 1, "gridColumnEnd": 3 }}>
                            <label style={{ "fontFamily": "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
                                Subject
                            </label>
                            <br />
                            <Select
                                // style={{ fontFamily: "Yantramanav", fontSize: "14px", width: "90%", padding: "8px 16px", border: "1px solid #b9b9b9", borderRadius: "1.5px" }}
                                placeholder='Select subject'
                                // options={selectOptions}
                                onChange={(data) => setSubject(data)}
                                styles={customStyles}
                                theme={theme => ({
                                    ...theme,
                                    // borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        //   primary25: 'hotpink',
                                        primary: 'orange',
                                    }
                                })
                                }
                            />
                        </div>
                        <div style={{ "gridColumnStart": 1, "gridColumnEnd": 3 }}>
                            <label style={{ "fontFamily": "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
                                How can we help you?
                            </label>
                            <br />
                            <textarea
                                rows="8"
                                style={{ fontFamily: "Yantramanav", "fontSize": "14px", width: "95%", padding: "8px 16px", border: "1px solid #b9b9b9", borderRadius: "1.5px", resize: "none" }}
                                name='message'
                                type='text'
                                placeholder='Write your message (required)'
                                ref={register({ required: true })}
                            />
                            {errors.message && <span style={{ fontFamily: "Yantramanav", fontSize: "14px", color: "#dc3545" }}>Please type how we can help you</span>}
                        </div>

                        <input
                            style={{
                                width: "208px",
                                backgroundColor: "#0077af",
                                padding: "8px 0",
                                fontFamily: "Yantramanav",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "white",
                                border: "1px solid #0077af",
                                borderRadius: "1.5px"
                            }}
                            type='submit'
                            value='Send contact form'
                        />
                    </form>
                </section>
            </section>
        </Layout>

    );

}

class ContactUsPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            contactComponent: Contact.FormComponent
        };
    }

    ContactBodySwitch = () => {
        switch (this.state.contactComponent) {
            case Contact.FormComponent:
                return (
                    <ContactUs
                        changeSuccess={() => this.setState({ contactComponent: Contact.SuccessComponent })}
                        changeError={() => this.setState({ contactComponent: Contact.ErrorComponent })}
                    />
                );
            case Contact.SuccessComponent:
                return (
                    <Layout>
                        <section style={{ "padding": "100px 6.5% 68px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={ContactUsSuccess} />
                            <h1 style={{ fontFamily: "Yantramanav", fontSize: "20px", marginTop: "42px" }}>Your contact form has been successfully sent!</h1>
                            <h2 style={{ fontFamily: "Yantramanav", fontSize: "16px", fontWeight: "500", color: "#5e5e5e", marginTop: "24px", marginBottom: "40px" }}>
                                You will be hearing from us soon.
                        </h2>
                            <div style={{ display: "flex" }}>
                                <Link to="/">
                                    <button
                                        style={{
                                            width: "208px",
                                            backgroundColor: "#0077af",
                                            padding: "8px 0",
                                            fontFamily: "Yantramanav",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            color: "white",
                                            border: "1px solid #0077af",
                                            borderRadius: "1.5px",
                                            marginRight: "16px"
                                        }}
                                    >
                                        Go back to homepage
                                    </button>
                                </Link>
                                <button
                                    style={{
                                        width: "208px",
                                        backgroundColor: "transparent",
                                        padding: "8px 0",
                                        fontFamily: "Yantramanav",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "#5e5e5e",
                                        border: "1px solid #5e5e5e",
                                        borderRadius: "1.5px",
                                        marginLeft: "16px"
                                    }}
                                    onClick={() => this.setState({ contactComponent: Contact.FormComponent })}
                                >
                                    Send another
                            </button>
                            </div>
                        </section>
                    </Layout>
                );
            case Contact.ErrorComponent:
                return (
                    <Layout>
                        <section style={{ "padding": "100px 6.5% 68px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={ContactUsError} />
                            <h1 style={{ fontFamily: "Yantramanav", fontSize: "20px", marginTop: "42px" }}>Oh no! We could not send your contact form!</h1>
                            <h2 style={{ fontFamily: "Yantramanav", fontSize: "16px", fontWeight: "500", color: "#5e5e5e", textAlign: "center", margin: "24px 0" }}>
                                Something happened on our end, and we could not send your contact form. Please try again later,
                                or contact us via email at <a style={{ color: "#3eb0b4", textDecoration: "none" }} href='mailto:contact@juristerra.net'>contact@juristerra.net</a> or
                                call us at <a style={{ color: "#3eb0b4", textDecoration: "none" }} href='tel:949-424-7045'>+1(949)424-7045</a>
                            </h2>
                            <Link to="/">
                                <button
                                    style={{
                                        width: "208px",
                                        backgroundColor: "#0077af",
                                        padding: "8px 0",
                                        fontFamily: "Yantramanav",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "white",
                                        border: "1px solid #0077af",
                                        borderRadius: "1.5px",
                                        marginRight: "16px"
                                    }}
                                >
                                    Go back to homepage
                                    </button>
                            </Link>
                        </section>
                    </Layout>
                );
        }
    }

    render() {
        return (
            <div>
                <this.ContactBodySwitch />
            </div>
        );
    }
}



export default ContactUsPage;